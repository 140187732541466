.chip-container {
    display: flex;
    flex-wrap: wrap;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    direction: rtl;
  }
  
  .chip {
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 6px 12px;
    margin: 4px;
    background-color: #ccc;
    color: #000;
    border-radius: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
  }
  
  .chip:hover {
    background-color: #aaa;
  }
  
  .chip button {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 8px;
    font-size: 14px;
    color: #000;
    padding: 2px 6px;
    border-radius: 50%;
    transition: background-color 0.3s;
    font-weight: bold;
  }
 
  
  .chip-input {
    border: none;
    outline: none;
    flex-grow: 1;
    padding: 6px;
    margin: 4px;
    min-width: 120px;
    font-size: 14px;
    background-color: #f1f1f1;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .chip-input:focus {
    background-color: #e0e0e0;
  }
  