/* FinancialReport.css */

/* body {
    font-family: 'Roboto', sans-serif;
    background-color: #f4f7fc;
    color: #333;
    direction: rtl;
  } */
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #4a4a4a;
    font-size: 2.5em;
  }
  
  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    gap: 20px;
    margin: 20px;
  }
  .charts {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
    gap: 0px;
    margin: 0px;
  }
  
  .card {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .card h2 {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: #555;
  }
  
  .card p {
    font-size: 1.1em;
    color: #777;
  }
  
  .chart-container {
    margin: 40px 20px;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .chart-container h2 {
    margin-bottom: 20px;
    font-size: 1.5em;
    color: #4a4a4a;
  }
  
  .chart-wrapper {
    position: relative;
    height: 400px;
  }
  
  @media (max-width: 768px) {
    .chart-wrapper {
      height: 300px;
    }
  }
  